<template>
  <div class="container">
    <!--  知识产权 -->
    <div class="property w">
      <div class="pea-title">
        <div class="one-content">
          <div class="copy-title">
            <div class="copy-con copy-con1">
              <span></span>
              <h3>知识产权</h3>
              <span></span>
            </div>
          </div>
        </div>
      </div>
      <Property></Property>

      <div class="pea-title">
        <div class="one-content">
          <div class="copy-title">
            <div class="copy-con copy-con1">
              <h3>知识产权价值测试</h3>
            </div>
          </div>
        </div>
      </div>

      <div class="form">
        <div
          style="background: #fff; padding: 30px 250px 50px; border-radius: 5px"
        >
          <el-form :model="form" label-width="120px" :rules="rules" ref="form">
            <el-form-item label="知识产权名称" prop="name">
              <el-input v-model="form.name"></el-input>
            </el-form-item>

            <el-form-item label="知识产权类别" prop="type">
              <el-radio-group v-model="form.type">
                <el-radio label="2">I类知识产权</el-radio>
                <el-radio label="1">II类知识产权</el-radio>
              </el-radio-group>
            </el-form-item>

            <el-form-item label="知识产权现状" prop="status">
              <el-radio-group v-model="form.status" @change="changeRadio">
                <el-radio label="已商用">已商用</el-radio>
                <el-radio label="1">未商用</el-radio>
                <!-- <el-radio label="0 ">未商用未盈利</el-radio> -->
              </el-radio-group>
              <div v-show="show">
                <p>对主要产品（服务）在技术上发挥核心支持作用</p>
                <el-radio-group v-model="form.status">
                  <el-radio label="2">强</el-radio>
                  <el-radio label="1.5">较强</el-radio>
                  <el-radio label="1 ">一般</el-radio>
                  <el-radio label="0.5 ">较弱</el-radio>
                </el-radio-group>
              </div>
            </el-form-item>
            <el-form-item label="是否自主研发" prop="research">
              <el-radio-group v-model="form.research">
                <el-radio label="1">是</el-radio>
                <el-radio label="0.5">否</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="技术的先进程度" prop="degree">
              <el-radio-group v-model="form.degree">
                <el-radio label="2">高</el-radio>
                <el-radio label="1.5">较高</el-radio>
                <el-radio label="1">一般</el-radio>
                <el-radio label="0.5">较低</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="研发/获得费用" prop="money">
              <el-input
                v-model="form.money"
                style="width: 90%; margin-right: 10px"
                type="number"
              ></el-input
              ><span>万元</span>
            </el-form-item>
            <el-form-item label="剩余保护期" prop="protect">
              <el-select placeholder="请选择" v-model="form.protect">
                <el-option label="1年" value="0.001"></el-option>
                <el-option label="2年" value="0.01"></el-option>
                <el-option label="3年" value="0.1"></el-option>
                <el-option label="4年" value="0.2"></el-option>
                <el-option label="5年" value="0.25"></el-option>
                <el-option label="6年" value="0.3"></el-option>
                <el-option label="7年" value="0.35"></el-option>
                <el-option label="8年" value="0.4"></el-option>
                <el-option label="9年" value="0.45"></el-option>
                <el-option label="10年" value="0.5"></el-option>
                <el-option label="11年" value="0.55"></el-option>
                <el-option label="12年" value="0.6"></el-option>
                <el-option label="13年" value="0.65"></el-option>
                <el-option label="14年" value="0.7"></el-option>
                <el-option label="15年" value="0.75"></el-option>
                <el-option label="16年" value="0.8"></el-option>
                <el-option label="17年" value="0.85"></el-option>
                <el-option label="18年" value="0.9"></el-option>
                <el-option label="19年" value="0.95"></el-option>
                <el-option label="20年" value="1"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="onSubmit('form')"
                >立即测试</el-button
              >
              <el-button @click="resetForm('form')">取消</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import Property from "../../../common/property";
export default {
  name: "ceshijz",
  components: {
    Property,
  },
  data() {
    return {
      form: {
        name: "",
        status: "",
        type: "",
        research: "",
        degree: "",
        money: "",
        protect: "",
      },
      show: false,
      num: 0,
      rules: {
        name: [
          { required: true, message: "请输入知识产权名称", trigger: "blur" },
        ],
        type: [
          { required: true, message: "请选择知识产权类别", trigger: "change" },
        ],
        status: [
          { required: true, message: "请选择知识产权现状", trigger: "change" },
        ],
        research: [
          { required: true, message: "请选择是否自主研发", trigger: "change" },
        ],
        degree: [
          {
            required: true,
            message: "请选择技术的先进程度",
            trigger: "change",
          },
        ],
        money: [{ required: true, message: "请输入费用", trigger: "blur" }],
        protect: [
          { required: true, message: "请选择剩余保护期", trigger: "change" },
        ],
      },
    };
  },
  created() {},
  methods: {
    changeRadio(val) {
      if (val == "已商用") {
        this.show = true;
      } else {
        this.show = false;
      }
    },
    onSubmit(formName) {
      console.log(this.form);
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.num =
            (Number(this.form.type) +
              Number(this.form.status) +
              Number(this.form.research) +
              Number(this.form.degree)) *
            Number(this.form.money) *
            Number(this.form.protect);
          console.log(this.num.toFixed(2));
          this.$confirm(
            "您的知识产权经初步评估，价值大概在" +
              '<span style="color:#ff0000;font-size:20px;font-weight: 700;">' +
              this.num.toFixed(0) +
              "</span>" +
              "万左右",
            "恭喜您",
            // {
            // dangerouslyUseHTMLString: true,

            //          confirmButtonText: "确定",
            //           confirmButtonText: "确定",
            //         callback: (action) => {
            //           this.$router.push({
            //   path: "/chat",
            // });
            // },
            // },
            // )
            {
              dangerouslyUseHTMLString: true,
              confirmButtonText: "在线咨询",
              cancelButtonText: "确定",
            }
          )
            .then(() => {
              this.$router.push({
                path: "/s_xq?id=108",
              });
            })
            .catch(() => {
              this.$refs[formName].resetFields();
            });
        } else {
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>

<style scoped lang="less">
.form {
  background-color: #3892eb;
  padding: 30px;
  margin: 0 auto;
  border-radius: 10px;
  margin-bottom: 30px;
}
.el-message-box__headerbtn {
  display: none !important;
}
 
/deep/.el-select {
  width: 100%;
}
@media screen and (max-width: 1200px) {
	.w{
		width: 98%;
	}
	/deep/.L_z{
		width: 100%;
		height: 14rem;
	}
	/deep/.headline{
		font-size: 1.8rem;
	}
	/deep/.explain{
		font-size: 1.6rem;
		line-height: 2.2rem;
	}
	/deep/.to_zt{
		width: 12rem;
		height: 3rem;
		line-height: 3rem;
		font-size: 1.6rem;
	}
}
</style>